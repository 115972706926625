import * as React from "react";
import { Link } from "gatsby";
import Layout from '../../../components/Layout';
import PPC from '../../../images/digital-marketing-ad-words.jpg';
import SEOAudit from '../../../images/digital-marketing-seo-audit.jpg';
import SEO from '../../../components/Seo';
import CTA from "../../../components/CTA";

const DigitalAdvertising = () => {

    return(
        <Layout>
            <SEO title="Digital Advertising for Healthcare" keywords="HealthOne Digital, digital advertising, google adwords, PPC for healthcare, paid social media ads"/>
            <section>
                <div className="pt-10 bg-blue-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <h1 className="mt-4 mb-5 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block">Digital Advertising</span> 
                                         <span className="block mt-0 lg:mt-2">For Healthcare</span>
                                    </h1>
                                    <span className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                        <Link className="hover:text-white" to="/services">SERVICES</Link> 
                                        <span> / </span> 
                                        <Link className="hover:text-white" to="/services/digital-marketing">DIGITAL MARKETING</Link>
                                        <span> / </span>
                                        DIGITAL ADVERTISING
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
           

            <section>
                <div className="bg-white overflow-hidden py-8 lg:pt-16 lg:pb-8">
                    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>

                        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                            <div>
                                <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-900 sm:text-4xl">Paid Digital Advertising for Healthcare</h2>
                            </div>
                        </div>

                        <div className="mt-2 lg:grid lg:grid-cols-2 lg:gap-8">

                            <div className="relative lg:row-start-1 lg:col-start-2">
                                <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                                    <defs>
                                        <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                                </svg>
                                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                                <figure>
                                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                        <img className="rounded-lg shadow-lg object-cover object-center" src={PPC} alt="Google Adwords and Pay Per Click word tiles" width="1184" height="1376"/>
                                    </div>
                                </figure>
                                </div>
                            </div>

                            <div className="mt-8 lg:mt-0">
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Paid Digital Advertising should be an essential part of your digital marketing strategy. All of the major platforms such as google, facebook, instagram have ad platforms that we can leverage to help reach your marketing goals.
                                    </p>
                                    
                                </div>
                         
                                <div className="mt-10 text-gray-500 space-y-6">
                                    <h3 className="mt-5 text-3xl leading-8 font-extrabold tracking-tight text-blue-900">Google AdWords & PPC</h3>
                                    <p className="text-lg">
                                    PPC stands for Pay Per Click and it’s a form of advertising on search engines where you get charged only when a user clicks through to your website.
                                    </p>
                                    <p className="text-lg">
                                    You may have heard of Google AdWords or even gotten a Google AdWords credit in your mail. AdWords is Google’s PPC platform. It’s by far the most popular PPC platform by virtue of google being the biggest search engine.
                                    </p>
                                    <p className="text-lg">
                                    Your PPC ads appear at the very top of google search results when someone searches for the keywords your ad is targeting. For example, if you’re a dental practice in Vancouver, you can target the keywords “dentist in Vancouver”. If you’re a physiotherapist in Calgary, you could target “injury rehab Calgary”.
                                    </p>
                                    <p className="text-lg">
                                    Keyword research becomes important as the more popular keywords you target, the higher your cost will be for every click.
                                    </p>
                                    <p className="text-lg">
                                    Bing, Microsoft’s search engine, also has a PPC platform. While not as popular as google, it still sees a respectable amount of traffic and may make sense for your practice to run PPC campaigns on.
                                    </p>
                                    <p className="text-lg">
                                    The keyword research for your PPC campaign can be further used to improve your organic content marketing efforts and your wider ranging SEO efforts. If you know what people are searching for in your healthcare niche, you can write content with those keywords embedded in it.
                                    </p>
                                </div>

                           
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="relative bg-white pt-4 pb-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={SEOAudit} alt=""/>
                              
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12">
                                <h3 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-3xl">
                                Paid Social Media Advertising
                                </h3>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Every successful social media strategy should involve a paid component. At this point, we’ve all been exposed to ads for various products while browsing Instagram or Facebook. It’s an extremely effective way of advertising your healthcare practice beyond your organic efforts.
                                    </p>
                                    <p className="text-lg">
                                    Outside of large health care providers, exposing patients to your social media content organically is increasingly difficult. Therefore, creating targeted ads specific to your demographic can help boost your social media following and patient booking.
                                    </p>
                                    <p className="text-lg">
                                    As these platforms allow you to target the exact patient demographic you prefer, you can tailor content specific to each demographic and the action you want them to take. Whether it be to follow you on social media, book an appointment, or learn more about your services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* CTA */}
         <CTA/>
        </Layout>
    )
}

export default DigitalAdvertising;